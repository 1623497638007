import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import Container from '@material-ui/core/Container'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import QuizTipoCorrida from '../../components/Quiz/quizTipoCorrida'
import ErrorBoundary from '../../components/ErrorBoundary'
import { ditoTrack } from '../../helpers/dito'

import * as S from './styles'

const QuizPage = () => {
  useEffect(() => {
    ditoTrack('acessou-quiz')
  }, [])

  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title={t('quiz_title_seo')} description={t('description_seo')} />
      <S.Bg>
        <Container>
          <S.PageTitle>
            <Trans>
              ENTENDA COMO O SEU ESTILO DE VIDA PODE DEFINIR O SEU TIPO IDEAL DE
              CORRIDA.
            </Trans>
          </S.PageTitle>
          <ErrorBoundary>
            <QuizTipoCorrida />
          </ErrorBoundary>
        </Container>
      </S.Bg>
    </Layout>
  )
}

export default QuizPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
